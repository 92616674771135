import React, {useState} from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';

import EnterUsername from '../components/EnterUsername';
import EnterPass from '../components/EnterPass';

import logo from '../assets/curb-logo/curb-logo@2x.png';

const Login = (props) => {
  const [email, setEmail] = useState(false);

  const continueToPass = (email) => {
    setEmail(email);
  }

  return (
    <Container className="login-BackgroundImage">
      <Row>
        <Col className="login-BackgroundImage"><div className="loginBackground"></div></Col>
        <Col className="loginDiv" >
          <div className="loginContent">
            <Image
              src={logo}
              className="CURB"
              alt="Curb"
            />
            <span className="PAY">pay</span>
            
            {!email ? <EnterUsername continue={continueToPass} />  :<EnterPass  auth={props.auth} email={email}/>}

          </div>
        </Col>
      </Row>
    </Container>

  );
}
    

export default Login;