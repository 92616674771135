import React from 'react';
import moment from 'moment';
import { Button } from 'react-bootstrap';

import approved from '../assets/approved.svg';
import rejected from '../assets/rejected.svg';
import visa from '../assets/visa.svg';
import mastercard from '../assets/mc.svg';
import discover from '../assets/discover.svg';
import amex from '../assets/amex.svg';

import { api_url, currency } from '../config';

export function dateTimeFormatter(cell, row) {
  if (!cell) {
    return "";
  }
  return (
    <span>
      {moment(cell).format("MMM Do YYYY, h:mm a")}
    </span>
  )
}

export function dateFormatter(cell, row) {
  if (!cell) {
    return "";
  }
  return (
    <span>
      {moment(cell).format("MMMM Do YYYY")}
    </span>
  )
}

export function moneyFormatter(cell, row) {
  if(!cell) return cell;
  return String(cell.toFixed(2));
}

export function currencyFormatter(cell) {
  if (cell < 0) {
    return `- ${currency + moneyFormatter(Math.abs(cell))}`
  } else {
    return `${currency + moneyFormatter(Math.abs(cell))}`
  }
}
export function serviceFormatter(cell) {
  var service;
  if(!cell) return;
  switch (cell.toLowerCase()) {
    case 'visa':
      service = <img alt="visa" src={visa} className="service-img centered" />;
      break;
    case 'mastercard':
      service = <img alt="mastercard" src={mastercard} className="service-img centered" />;
      break;
    case 'discover':
      service = <img alt="discover" src={discover} className="service-img centered" />;
      break;
    case 'amex':
      service = <img alt="amex" src={amex} className="service-img centered" />;
      break;
    default:
      service = <div>{cell}</div>;
  }
  return service;
}

export function statusFormatter(cell) {
  let icon, text;

  if (cell === "000") {
    icon = approved;
    text = 'Approved';
  } else {
    icon = rejected;
    text = 'Rejected';
  }

  return (
    <span className="horizontal-cont">
      <img style={{ marginLeft: "3px" }} alt="" src={icon} />
      <div style={{ marginLeft: "6px" }}>{" " + text}</div>
    </span>
  )
}

export function statusFormatterCSV(cell, row) {
  if (cell === "000") {
    return 'Approved';
  } else {
    return 'Rejected';
  }
}

export function exportInvoiceFormatter(cell, row) {
  return (
    <Button variant="outline-success" size="sm" href={api_url + "/Invoice/" + row.invoice_id} download>Report</Button>
  )
}

export function exportDepositFormatter(cell, row) {
  return (
    <Button variant="outline-success" size="sm" href={api_url + "/Deposit/" + row.deposit_id} download>Report</Button>
  )
}