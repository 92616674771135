import React, { useState }  from 'react';

import { Form, Button} from 'react-bootstrap';

const EnterUsername = (props) => {

  const [email, setEmail] = useState(false);

  const handleChange = (event) => {
    setEmail(event.target.value);
  }
  
  const validateEmail = (email) => {
    const expression = /\S+@\S+/;
    return expression.test(email.toLowerCase());
  }

  const submit = (event) => {
    event.preventDefault();
    if(!validateEmail(email)){
      alert("Unable to continue without entering a valid email");
    }else{
      props.continue(email);
    }
  }

    return (
        <Form className="loginForm">
            <Form.Group>
            <Form.Label className="Log-In">LOG IN</Form.Label>
            <Form.Control
                type="email"
                placeholder="Email"
                onChange={handleChange}
                autoFocus
            />
            </Form.Group>
            <Button className="LoginButton" type="submit" onClick={submit.bind(this)}>
                Continue
            </Button>
        </Form>
    );

}

export default EnterUsername;