import React, { useState, useEffect } from 'react';
import { Container, Row, Col, FormControl, Button } from 'react-bootstrap';
import moment from 'moment';

import DatePicker from './DatePicker';

const Filters = (props) => {
  const [filterComps, setFilterComps] = useState();
  const { filters, apply } = props;

  const [fields, setFields] = useState({ ...filters });

  const clear = (fields) => {
    var temp = { ...fields };
    Object.keys(temp).forEach(function (fields) {
      temp[fields].value = "";
    });
    setFields(temp);
    props.setFilters(temp);
  }

  useEffect(() => {
    var i = 0;
    var prop_filters = [];
    var cur_row = [];

    const handleChange = (filter, event) => {
      var vals = { ...fields };
      vals[filter].value = event.target.value;
      setFields(vals);
    }

    const handleDateChange = (selectedDay, modifiers, dayPickerInput, filter) => {
      var vals = { ...fields };
      if (!dayPickerInput.getInput().value.trim()) {
        vals[filter].value = "";
      } else {
        vals[filter].value = moment(selectedDay).format("YYYY-MM-DD");
      }
      setFields(vals);
    }

    Object.keys(filters).forEach(function (filter) {
      if (i % 4 === 0) {
        prop_filters.push(<Row key={i}>{cur_row}</Row>);
        cur_row = [];
      }
      if (filters[filter].choices) {
        cur_row.push(
          <Col key={filter}>
            <h3>{filter}</h3>
            <FormControl className="filter-input" as="select" value={fields[filter].value} onChange={e => handleChange(filter, e)}>
              {filters[filter].choices.map(choice => <option key={choice}>{choice}</option>)}
            </FormControl>
          </Col>
        )
      } else if (filters[filter].type === "date") {
        cur_row.push(
          <Col key={filter}>
            <h3>{filter}</h3>
            <DatePicker placeholder={fields[filter].value} selected={fields[filter].value} onChange={(selectedDay, modifiers, dayPickerInput) => handleDateChange(selectedDay, modifiers, dayPickerInput, filter)} />
          </Col>
        )
      } else {
        cur_row.push(
          <Col key={filter}>
            <h3>{filter}</h3>
            <FormControl className="filter-input" value={fields[filter].value} onChange={e => handleChange(filter, e)} />
          </Col>
        )
      }
      i++;
    });

    while (i % 4 !== 0) {
      cur_row.push(<Col key={i}/>)
      i++;
    }
    prop_filters.push(<Row key={i}>{cur_row}</Row>);
    setFilterComps(prop_filters);
  }, [filters, fields]);

  if (!props.visible) return null;

  return (
    <div className="filters">
      <Container>
        <Row>
          <Col />
          <Col>
            <Button className="clearFilters" onClick={() => clear(fields)}>Clear</Button>
            <Button className="applyFilters" onClick={() => apply(fields)}>Apply</Button>
          </Col>
        </Row>
        {filterComps}
      </Container>
    </div>
  )
}

export default Filters