import React, { useState, useEffect, useCallback } from 'react';
import { Button, ButtonToolbar } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator'
import filterFactory from 'react-bootstrap-table2-filter';
import moment from 'moment';

import Filter from './Filter';

import { ExportCSV } from '../resources/TableHelpers';

import GraphQL from '../resources/GraphQL';

import spinner from "../assets/loading.svg";

import { env, page_size } from '../config';

const Table = (props) => {

  const [loading, setLoading] = useState(true);
  const [displayedFilters, setDisplayedFilters] = useState([]);
  const [data, setData] = useState([]);
  const [pageSize, setPageSize] = useState(page_size);
  const [pagesLoaded, setPagesLoaded] = useState(1);
  const [additionalArgs] = useState(props.args !== undefined ? props.args: {}  );

  const { filters, setFiltersVisible, filtersVisible } = props;

  const setFilters = (fils) => {
    setData([]);
    setPagesLoaded(1);
    props.setFilters(fils);
  }

  const callbackFilters = useCallback(setFilters, []);

  useEffect(() => {
    setLoading(true);

    var args = {
      "client_id": props.auth.userProfile.app_metadata.apps.curb_pay[env].client_id,
      "page": pagesLoaded,
      "page_size": page_size,
      ...additionalArgs
    }

    if (typeof filters === 'object' && filters !== null) {
      var aF = [];

      const deleteFilter = (arg) => {
        var tempFilters = { ...filters };
        tempFilters[arg].value = "";
        callbackFilters(tempFilters);
      }

      Object.keys(filters).forEach(function (filter) {
        if (filters[filter].value) {
          if (filters[filter].type === "string" || filters[filter].type === "date") {
            args[filters[filter].arg] = `"${filters[filter].value}"`;
          } else {
            args[filters[filter].arg] = filters[filter].value;
          }
          aF.push(<span className='appliedFilter' key={filter}>{filter}: {filters[filter].value} <span className='deleteFilter' onClick={() => deleteFilter(filter)}>x</span></span>)
        }
      });
      setDisplayedFilters(aF);
    }
    
    GraphQL(props.resource, args, props.auth.getAccessToken(), props.response)
      .then(res => {
        setData(t => t.concat(res));
        setLoading(false);
      })
      .catch(err => {
        console.error(err);
        setLoading(false);
      });

  }, [pagesLoaded, props.auth, filters, props.resource, props.response, callbackFilters, additionalArgs]);

  return (
    <div className="table-component vertical-cont">
      <ToolkitProvider
        keyField={props.keyField}
        data={data}
        title={props.title}
        expandRow={props.expandRow}
        columns={props.columns}
        search
        exportCSV={{
          fileName: `${props.resource}-${moment().format("YYYY-MM-DD-H-MM-SS")}.csv`
        }}
      >
        {
          toolKitProps => (
            <div className="table-component">

              {props.filters != null &&
                <Filter
                  displayedFilters={displayedFilters}
                  filters={props.filters}
                  setFilters={setFilters}
                  showFilters={filtersVisible}
                  setShowFilters={setFiltersVisible}
                />
              }
              {props.header != null && props.header}
              <PaginationProvider
                pagination={paginationFactory({ custom: true, totalSize: data.length, sizePerPage: pageSize })}
              >
                {
                  ({
                    paginationProps,
                    paginationTableProps
                  }) => (
                      <div>
                        <div className="table-cont" style={{ paddingTop: filtersVisible ? 65 + (filters? Math.ceil(Object.keys(filters).length / 4) * 70 : 0) : 0 }} onClick={() => setFiltersVisible(false)}>
                          <BootstrapTable {...toolKitProps.baseProps}
                            className="table"
                            bordered={false}
                            headerClasses="header"
                            filter={filterFactory()}
                            striped={true}
                            expandRow={props.expandRow}
                            {...paginationTableProps}
                          />
                        </div>

                        <ButtonToolbar className="table-buttons justify-content-between" onClick={() => setFiltersVisible(false)}>
                          <ExportCSV classname='float-left' variant="outline-success" size="sm" {...toolKitProps.csvProps} />

                          <Button
                            className='LoadMoreButton float-center'
                            onClick={(e) => {
                              e.preventDefault();
                              setPageSize(pageSize + page_size);
                              setPagesLoaded(pagesLoaded + 1);
                            }}
                          >
                            {'Load More'}
                          </Button>

                        </ButtonToolbar>

                        {loading && (<div className='table-spinner'><img src={spinner} alt="Loading" /></div>)}
                      </div>
                    )
                }
              </PaginationProvider>
            </div>
          )
        }
      </ToolkitProvider>
    </div>

  )
}

export default Table;
