import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';

import Filters from './Filters';

const Filter = (props) => {
  const { showFilters, setShowFilters, filters, setFilters, displayedFilters } = props;

  const applyFilters = (fields) => {
    var tempFilters = { ...filters };
    Object.keys(fields).forEach(function (filter) {
      tempFilters[filter].value = fields[filter].value;
    });
    setFilters(tempFilters);
  }

  return (
    <div className="table-searchbar">

      <div className="filter" onClick={() => setShowFilters(!showFilters)}><FontAwesomeIcon icon={faFilter} className="filter-icon" />{displayedFilters}</div>

      <Filters
        filters={filters}
        setFilters={setFilters}
        visible={showFilters}
        apply={applyFilters}
      />
    </div>
  )
}


export default Filter