// src/components/Profile.js

import React from "react";

import { Container, Row, Col } from 'react-bootstrap';

import { env } from '../config';

const Profile = (props) => {
  return (
    <div className="userProfile">
      <img className="float-right" src={props.auth.userProfile.picture} width="150" height="150" alt="Profile" />

      <h2>{props.auth.userProfile.user_metadata.name}</h2>
      
      <div className="expandRow">
        <Container>
          <Row>
            <Col>
              <h3>Email</h3>
              <p>{props.auth.userProfile.email}</p>
            </Col>
            <Col>
              <h3>Company</h3>
              <p>{props.auth.userProfile.user_metadata.company}</p>
            </Col>
          </Row>
          <Row>
            <Col>
              <h3>Phone</h3>
              <p>{props.auth.userProfile.user_metadata.phone}</p>
            </Col>
            <Col>
              <h3>Client ID</h3>
              <p>{props.auth.userProfile.app_metadata.apps.curb_pay[env].client_id}</p>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Profile;