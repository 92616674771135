import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import { dateFormatter, moneyFormatter, exportInvoiceFormatter, currencyFormatter } from '../resources/Formatters';
import { expandColumnRenderer } from '../resources/TableHelpers';

import Table from '../components/Table';

import { currency } from '../config';

const Invoices = (props) => {

  const [filters, setFilters] = useState({
    "From (YYYY-M-D)": { "arg": "start", "type": "date", "value": "" },
    "To (YYYY-M-D)": { "arg": "end", "type": "date", "value": "" },
    "Invoice ID": { "arg": "invoice_id", "value": "" },
    "Amount": { "arg": "total_submitted", "value": "" },
    "Transaction Fees": { "arg": "transaction_fees", "value": "" },
    "Net": { "arg": "net", "value": "" },
    "Service Fees": { "arg": "service_fees", "value": "" },
    "Chargebacks Reversals": { "arg": "chargebacks_reversals", "value": "" },
  });

  var response = `
    client_id, 
    invoice_id, 
    date,  
    start, 
    end, 
    total_submitted, 
    transaction_fees, 
    net, 
    service_fees, 
    chargebacks_reversals`;

  const columns = [
    {
      dataField: 'start',
      text: 'Start Date',
      sort: true,
      formatter: dateFormatter,
      style: {
        width: '150px'
      }
    },
    {
      dataField: 'end',
      text: 'End Date',
      sort: true,
      formatter: dateFormatter,
      style: {
        width: '150px'
      }
    },
    {
      dataField: 'date',
      text: 'Invoice Date',
      formatter: dateFormatter,
      hidden: false,
      style: {
        width: '150px'
      }
    },
    {
      dataField: 'total_submitted',
      text: `Amount, ${currency}`,
      csvText: `Amount`,
      sort: true,
      formatter: moneyFormatter,
      style: {
        width: '25px'
      }
    },
    {
      dataField: 'invoice_id',
      text: 'Invoice ID',
      sort: true,
      style: {
        width: '25px'
      }
    },
    {
      dataField: '',
      text: 'Report',
      formatter: exportInvoiceFormatter,
      csvExport: false,
      style: {
        width: '25px'
      }
    },
    {
      dataField: 'transaction_fees',
      text: 'Transaction Fees',
      hidden: true
    },
    {
      dataField: 'net',
      text: 'Net',
      hidden: true
    },
    {
      dataField: 'service_fees',
      text: 'Service Fees',
      hidden: true
    },
    {
      dataField: 'chargebacks_reversals',
      text: 'Chargebacks Reversals',
      hidden: true
    }
  ]

  const expandRow = {
    renderer: row => (
      <div className="expandRow">
        <Container>
          <Row>
            <Col>
              <h3>Transaction Fees</h3>
              <p>{currencyFormatter(row.transaction_fees)}</p>
            </Col>
            <Col>
              <h3>Net</h3>
              <p>{currencyFormatter(row.net)}</p>
            </Col>
            <Col>
              <h3>Service Fees</h3>
              <p>{currencyFormatter(row.service_fees)}</p>
            </Col>
            <Col>
              <h3>Chargebacks Reversals</h3>
              <p>{currencyFormatter(row.chargebacks_reversals)}</p>
            </Col>
          </Row>
          <Row>
           
          </Row>
        </Container>
      </div>
    ),
    showExpandColumn: true,
    onlyOneExpanding: true,
    expandHeaderColumnRenderer: () => (<div />),
    expandColumnRenderer: expandColumnRenderer
  }

  return (
    <Table
      columns={columns}
      title='Invoices'
      filters={filters}
      setFilters={setFilters}
      resource='invoices'
      response={response}
      expandRow={expandRow}
      keyField="invoice_id"
      {...props}
    />
  );
}

export default Invoices;