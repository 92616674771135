import React from 'react';

import arrowpointdown from '../assets/arrow-point-down.svg';
import arrowpointup from '../assets/arrow-point-up.svg';

import { Button } from 'react-bootstrap';

export const expandColumnRenderer = ({ expanded }) => {
  if (expanded) {
    return (
      <img alt="" src={arrowpointup} className="arrow-point" />
    );
  }
  return (
    <img alt="" src={arrowpointdown} className="arrow-point" />
  );
}

export const ExportCSV = (props) => {
  const handleClick = () => {
    props.onExport();
  };
  return (
    <div>
      <Button className="ExportCSVButton" onClick={handleClick}>Export CSV</Button>
    </div>
  );
};

export const sizePerPageRenderer = ({ options, currSizePerPage, onSizePerPageChange, fetcher, page_number}) => (
  <div className=''>
  <Button
    variant="outline-success"
    className='centered'
    onClick={(e) => {
      e.preventDefault();
      onSizePerPageChange(parseInt(currSizePerPage) + 10);
    }}
  >
    {'Load More'}
  </Button>
  </div>
);

export const pageListRenderer = () => { return }