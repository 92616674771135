// src/Profile/Dashboard.js

import React, { useState, useEffect } from 'react';
import BarGraph from './BarGraph';
import Loading from './Loading';

import { env } from '../config';
import moment from 'moment';

import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";


import GraphQL from '../resources/GraphQL';

const DashboardGraph = (props) => {

  const [period, setPeriod] = useState(new Date());

  const [data, setData] = useState();
  const [yScale, setyScale] = useState(false);
  const [loading, setLoading] = useState(true);
  const { userProfile, getAccessToken } = props.auth;

  //Daily Volume 
  useEffect(() => {
    setLoading(true);

    var args = {
      "client_id": userProfile.app_metadata.apps.curb_pay[env].client_id,
      "period": moment(period).format("\"YYYY-MM-DD\"")
    }

    var response = `
        date,
        ${props.keys}`;

    const fillAndSetData = (data) => {
      var startOfMonth = moment(period).startOf('month').toDate();;
      var endOfMonth = moment(period).endOf('month').toDate();;
      var daysOfMonth = [];
      for (var d = startOfMonth; d <= endOfMonth; d.setDate(d.getDate() + 1)) {
        daysOfMonth.push({ "date": moment(d).format("YYYY-MM-DD") });
      }
      var cur;
      if (data.length > 0) {
        setyScale(true);
      } else {
        setyScale(false);
      }
      data.forEach(item => {
        cur = daysOfMonth.find(d => d.date === moment(item.date).format("YYYY-MM-DD"));
        cur[props.resource] = item[props.resource];
      })

      setData(daysOfMonth);
    }

    GraphQL(props.resource, args, getAccessToken(), response)
      .then(res => {
        fillAndSetData(res);
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
      });

  }, [period, props.resource, props.keys, userProfile, getAccessToken]);

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="dashboard-cont">
      <span className="dashboard-graph-header">
        <h3 className='dashboard-title'>{props.title}</h3>
        <DatePicker
          selected={period}
          onChange={setPeriod}
          dateFormat="yyyy-MM"
          showMonthYearPicker
        />
      </span>

      <BarGraph data={data} yScale={yScale} resource={props.resource} keys={props.keys} />
    </div>
  )
}
export default DashboardGraph