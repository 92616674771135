import React from 'react';

class Footer extends React.Component {
  render() {
    return (
      <footer className="float-right">© 2019 Curb Mobility</footer>
    )
  }
}

export default Footer;