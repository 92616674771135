// src/Profile/Profile.js

import React, { useState } from 'react';
import { Col, Row, Container, Form, Button } from 'react-bootstrap';
import { env, api_url } from '../config';

const EditUser = (props) => {

  const { user, auth } = props;

  const { roles } = user.appMetadata.apps.curb_pay[env];
  const [name, setName] = useState(user.userMetadata.name);
  const [email, setEmail] = useState(user.email);
  const [phone, setPhone] = useState(user.userMetadata.phone);

  const [allRoles, setAllRoles] = useState({
    "dashboard": {"checked": roles.includes("dashboard")},
    "transactions": {"checked": roles.includes("transactions")},
    "deposits": {"checked": roles.includes("deposits")},
    "invoices": {"checked": roles.includes("invoices")},
    "admin": {"checked": roles.includes("admin"), "disabled": user.userId === auth.userProfile.user_id}
  });

  const role_checkboxes = [];

  const handleRole = (role) => {
    var vals = { ...allRoles };
    vals[role].checked = !allRoles[role].checked;
    setAllRoles(vals);
  }

  Object.keys(allRoles).forEach((role) => {
    role_checkboxes.push(
      <Form.Check
        key={role}
        label={role}
        disabled={allRoles[role].disabled}
        onChange={() => handleRole(role)}
        checked={allRoles[role].checked} />
    )
  }
  )

  const updateUser = (e) => {
    var new_roles = [];
    Object.keys(allRoles).forEach((role) => {
      if (allRoles[role].checked) new_roles.push(role);
    });

    fetch(api_url + '/UpdateAuth0User', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': props.auth.getAccessToken()
      },
      body: JSON.stringify({
        "email": email,
        "firstname": name,
        "phone": phone,
        "company": user.userMetadata.company,
        "roles": new_roles,
        "client_id": user.appMetadata.apps.curb_pay[env].client_id,
        "id": user.userId
      }),
    })
      .then(response => response.json())
      .then(responseJson => {
        if (responseJson.ExceptionMessage && responseJson.ExceptionMessage === "The user already exists.") {
          alert("Your account already exists, please contact support for further help");
        } else {
          alert("Updates Applied");
        }
      })
      .catch(error => {
        console.error(`An Unexpected Error Occurred while updating account, try again later`);
      });
  }

  console.log(allRoles);

  return (
    <div className="userProfile">
      <div className="expandRow">
        <Container>
          <Row>
            <Col>
              <h3>Name</h3>
              <Form.Control
                value={name}
                onChange={(event) => { setName(event.target.value) }}
              />
            </Col>
            <Col>
              <h3>Client ID</h3>
              <p>{user.appMetadata.apps.curb_pay[env].client_id}</p>
            </Col>
          </Row>

          <Row>
            <Col>
              <h3>Email</h3>
              <Form.Control
                value={email}
                onChange={(event) => { setEmail(event.target.value) }}
                autoFocus
              />
            </Col>
            <Col>
              <h3>Company</h3>
              <p>{user.userMetadata.company}</p>
            </Col>
          </Row>
          <Row>
            <Col>
              <h3>Phone</h3>
              <Form.Control
                value={phone}
                onChange={(event) => { setPhone(event.target.value) }}
              />
            </Col>
            <Col />

          </Row>
          <Row>
            <Col>
              <h3>Roles</h3>
              {role_checkboxes}
            </Col>
            <Col>
              <Button className='LoadMoreButton float-right' onClick={(e) => updateUser()}>
                Apply Changes
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );

}

export default EditUser;