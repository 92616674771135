import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap'; 

import CreateUser from '../components/CreateUser';

import logo from '../assets/curb-logo/curb-logo@2x.png';


const Signup = (props) => {

  return (
    <Container className="login-BackgroundImage">
      <Row>
        <Col className="login-BackgroundImage"><div className="loginBackground"></div></Col>
        <Col className="loginDiv" >
          <div className="signUpContent">
            <Image
              src={logo}
              className="CURB"
              alt="Curb"
            />
            <span className="PAY">pay</span>
            
            <CreateUser {...props}/>
          </div>
        </Col>
      </Row>
    </Container>

  );
}

export default Signup;