import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import { dateFormatter, moneyFormatter, exportDepositFormatter } from '../resources/Formatters';
import { expandColumnRenderer } from '../resources/TableHelpers';

import Table from '../components/Table';
import EmbeddedTable from '../components/EmbeddedTable';

import { response as TransactionResponse, columns as TransactionColumns } from './Transactions'

import { currency } from '../config';

const Deposits = (props) => {

  const [filters, setFilters] = useState({
    "From (YYYY-M-D)": { "arg": "start", "type": "date", "value": "" },
    "To (YYYY-M-D)": { "arg": "end", "type": "date", "value": "" },
    "SubTotal": { "arg": "subtotal", "value": "" },
    "Processing Fees": { "arg": "commission", "value": "" },
    "Adjustments": { "arg": "adjustments", "value": "" },
    "Total": { "arg": "total", "value": "" },
  });

  var response = `
    client_id, 
    date,  
    subtotal, 
    commission, 
    adjustments, 
    total,
    deposit_id`;

  const columns = [{
    dataField: 'date',
    text: 'Date',
    sort: true,
    formatter: dateFormatter,
    style: {
     width: '120px',
     maxWidth: '120px',
     minWidth: '120px'
    }
  },
  {
    dataField: 'subtotal',
    text: `SubTotal, ${currency}`,
    csvText: `SubTotal`,
    sort: true,
    formatter: moneyFormatter,
    style: {
      width: '50px',
      maxWidth: '50px',
      minWidth: '50px'
    }
  },
  {
    dataField: 'commission',
    text: `Processing Fees, ${currency}`,
    csvText: `Processing Fees`,
    sort: true,
    formatter: moneyFormatter,
    style: {
      width: '50px',
      maxWidth: '50px',
      minWidth: '50px'
    }
  },
  {
    dataField: 'adjustments',
    text: `Adjustments, ${currency}`,
    csvText: `Adjustments`,
    sort: true,
    formatter: moneyFormatter,
    style: {
      width: '50px',
      maxWidth: '50px',
      minWidth: '50px'
    }
  },
  {
    dataField: 'total',
    text: `Total, ${currency}`,
    csvText: `Total`,
    sort: true,
    formatter: moneyFormatter,
    style: {
      width: '50px',
      maxWidth: '50px',
      minWidth: '50px'
    }
  },
  {
    dataField: '',
    text: 'Report',
    formatter: exportDepositFormatter,
    csvExport: false,
    style: {
      width: '30px',
      maxWidth: '30px',
      minWidth: '30px'
    }
  }
  ]

  const expandRow = {
    renderer: row => (
      <div className="expandRow">
        <Container>
          <Row >
            <Col>
              <h3>Deposit ID:</h3>
            </Col>
            <Col>
              <p className="float-right">{row.deposit_id}</p>
            </Col>
          </Row>
          <Row>
            <Col>
              <EmbeddedTable
                columns={TransactionColumns}
                resource='transactions'
                args={{ "deposit_id": `"${row.deposit_id}"` }}
                response={TransactionResponse}
                keyField="transaction_id"
                auth={props.auth}
              />
            </Col>
          </Row>
        </Container>
      </div>
    ),
    showExpandColumn: true,
    onlyOneExpanding: true,
    expandHeaderColumnRenderer: () => (<div />),
    expandColumnRenderer: expandColumnRenderer
  }

  return (
    <Table
      columns={columns}
      title='Deposits'
      filters={filters}
      setFilters={setFilters}
      resource='deposits'
      response={response}
      expandRow={expandRow}
      keyField="deposit_id"
      {...props}
    />
  );
}

export default Deposits;