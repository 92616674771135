import React from 'react';
import { InputGroup } from 'react-bootstrap';

import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';

import calendar from '../assets/calendar.svg';

class DatePicker extends React.Component {

  render() {
    return (
      <InputGroup className="">
        <InputGroup.Prepend>
          <InputGroup.Text className="datepicker-pre">
            <img alt="" src={calendar} className="calendar" />
          </InputGroup.Text>
        </InputGroup.Prepend>
        <DayPickerInput className="datepicker"
          placeholder={this.props.placeholder}
          selectedDay={this.props.selected}
          onDayChange={this.props.onChange}
        />
      </InputGroup>
    );
  }
};

export default DatePicker