import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import { dateTimeFormatter, moneyFormatter, serviceFormatter, statusFormatter, statusFormatterCSV } from '../resources/Formatters';
import { expandColumnRenderer } from '../resources/TableHelpers';

import Table from '../components/Table';

import { currency } from '../config.json';

export const response = `
      client_id, 
      service, 
      date, 
      crtran_id, 
      card_num, 
      deposit_id, 
      approval_code,  
      amount, 
      terminal_id, 
      terminal_sn, 
      merchant_id, 
      batch_num, 
      bank_response, 
      employee_id, 
      service_num,
      action_code`;

export const columns = [
    {
      dataField: 'date',
      text: 'Date',
      formatter: dateTimeFormatter,
      sort: true,
      style: {
        width: '185px',
        minWidth:'135px'
      }
    }, {
      dataField: 'service',
      text: 'Service',
      formatter: serviceFormatter,
      style: {
        width: '20px',
        minWidth: '10px'
      }
    }, {
      dataField: 'card_num',
      text: 'Number',
      style: {
        width: '75px',
        minWidth: '50px'

      }
    },
    {
      dataField: 'approval_code',
      text: 'Approval Code',
      style: {
        width: '100px',
        minWidth: '50px'
      }
    },
    {
      dataField: 'action_code',
      text: 'Status',
      formatter: statusFormatter,
      csvFormatter: statusFormatterCSV,
      filterValue: statusFormatterCSV,
      style: {
        width: '50px',
        minWidth: '30px'
      }
    },
    {
      dataField: 'amount',
      text: `Amount, ${currency}`,
      csvText: `Amount`,
      formatter: moneyFormatter,
      csvFormatter: moneyFormatter,
      style: {
        width: '90px',
        minWidth: '60px',
        textAlign: 'right',
      },
      headerStyle: {
        textAlign: 'right'
      }
    },
    {
      dataField: 'terminal_id',
      text: 'Terminal ID',
      hidden: true
    }, {
      dataField: 'terminal_sn',
      text: 'Terminal SN',
      hidden: true
    }, {
      dataField: 'merchant_id',
      text: 'Merchant ID',
      hidden: true
    },
    {
      dataField: 'batch_num',
      text: 'Batch #',
      hidden: true
    },
    {
      dataField: 'crtran_id',
      text: 'Tran #',
      hidden: true
    },
    {
      dataField: 'bank_response',
      text: 'Bank Response',
      hidden: true
    },
    {
      dataField: 'employee_id',
      text: 'Employee ID',
      hidden: true
    },
    {
      dataField: 'service_num',
      text: 'Service #',
      hidden: true
    }
  ]

const Transactions = (props) => {
  const [filters, setFilters] = useState({
    "From (YYYY-M-D)": { "arg": "start", "type": "date", "value": "" },
    "To (YYYY-M-D)": { "arg": "end", "type": "date", "value": "" },
    "Service": { "arg": "service", "type": "string", "choices": ["","Visa", "Mastercard", "AMEX", "Discover", "Other"], "value": "" },
    "Transaction #": { "arg": "crtran_id", "type": "int", "value": "" },
    "Card #": { "arg": "card_num", "type": "string", "value": "" },
    "Deposit ID": { "arg": "deposit_id", "type": "string", "value": "" },
    "Approval Code": { "arg": "approval_code", "type": "string", "value": "" },
    "Amount": { "arg": "amount", "type": "int", "value": "" },
    "Terminal ID": { "arg": "terminal_id", "type": "string", "value": "" },
    "Terminal SN": { "arg": "terminal_sn", "type": "string", "value": "" },
    "Merchant ID": { "arg": "merchant_id", "type": "string", "value": "" },
    "Batch #": { "arg": "batch_num", "type": "string", "value": "" },
    "Bank Response": { "arg": "bank_response", "type": "string", "value": "" },
    "Employee ID": { "arg": "employee_id", "type": "string", "value": "" },
    "Service #": { "arg": "service_num", "type": "int", "value": "" }
  });

  const expandRow = {
    renderer: row => (
      <div className="expandRow">
        <Container>
          <Row>
            <Col>
              <h3>Terminal ID</h3>
              <p>{row.terminal_id}</p>
            </Col>
            <Col>
              <h3>Terminal SN</h3>
              <p>{row.terminal_sn}</p>
            </Col>
            <Col>
              <h3>Merchant ID</h3>
              <p>{row.merchant_id}</p>
            </Col>
            <Col>
              <h3>Batch #</h3>
              <p>{row.batch_num}</p>
            </Col>
          </Row>
          <Row>
            <Col>
              <h3>Tran #</h3>
              <p>{row.crtran_id}</p>
            </Col>
            <Col>
              <h3>Bank Response</h3>
              <p>{row.bank_response}</p>
            </Col>
            <Col>
              <h3>Employee ID</h3>
              <p>{row.employee_id}</p>
            </Col>
            <Col>
              <h3>Service #</h3>
              <p>{row.service_num}</p>
            </Col>
          </Row>
        </Container>
      </div>
    ),
    showExpandColumn: true,
    onlyOneExpanding: true,
    expandHeaderColumnRenderer: () => (<div />),
    expandColumnRenderer: expandColumnRenderer
  }

  return (
    <Table
      columns={columns}
      title='Transactions'
      filters={filters}
      setFilters={setFilters}
      resource='transactions'
      response={response}
      expandRow={expandRow}
      keyField="crtran_id"
      {...props}
    />
  );
}

export default Transactions;