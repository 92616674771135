import React from 'react';

import { dateTimeFormatter } from '../resources/Formatters';
import { expandColumnRenderer } from '../resources/TableHelpers';

import Table from '../components/Table';
import EditUser from '../components/EditUser';
import InviteUser from '../components/InviteUser';

import { env } from '../config';

const Admin = (props) => {

  const response = `
  userId,
  userName,
  email,
  nickName,
  userMetadata,
  appMetadata,
  lastLogin,
  picture`;

  const additionalArgs = { "env": `"${env}"` };
  const expandRow = {
    renderer: row => (
      <EditUser user={row} {...props}/>
    ),
    showExpandColumn: true,
    onlyOneExpanding: true,
    expandHeaderColumnRenderer: () => (<div />),
    expandColumnRenderer: expandColumnRenderer
  }

  const columns = [
    {
      dataField: 'email',
      text: 'Email',
      style: {
        width: '100px'
      }
    }, {
      dataField: 'userMetadata.name',
      text: 'Name',
      style: {
        width: '70px'
      }
    }, {
      dataField: 'lastLogin',
      text: 'Last Login',
      formatter: dateTimeFormatter,
      style: {
        width: '120px'
      }
    }
  ]

  const header = <InviteUser {...props}/>;
  return (
    <Table
      columns={columns}
      header={header}
      title='User Administration'
      resource='users'
      args={additionalArgs}
      response={response}
      expandRow={expandRow}
      keyField="userId"
      {...props}
    />
  );
}

export default Admin;
