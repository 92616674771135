import React from 'react';
import { Container, Row, Col, Image, Button } from 'react-bootstrap';

import logo from '../assets/curb-logo/curb-logo@2x.png';

const Verify = (props) => {

  return (
    <Container className="login-BackgroundImage">
      <Row>
        <Col className="login-BackgroundImage"><div className="loginBackground"></div></Col>
        <Col className="loginDiv" >
          <div className="verifyContent">
            <Image
              src={logo}
              className="CURB"
              alt="Curb"
            />
            <span className="PAY">pay</span>
            <h2>
              You're Almost There!
            </h2>
            <p>
              We have sent an email to verify your account credentials, please click on the verification link in the email then you will have access to your account.
              </p>
              <Button className="button" onClick={() => props.history.push("/")} >Ok</Button>
          </div>
        </Col>
      </Row>
    </Container>
  );
  
}

export default Verify;