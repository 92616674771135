import React, { useState } from 'react';
import { Form, Button, Alert } from 'react-bootstrap';
import {api_url, env} from '../config';
const InviteUser = (props) => {
  const { filtersVisible, setFiltersVisible, auth } = props;
  const [email, setEmail] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const sendInvite = (e) => {
    e.preventDefault();
    fetch(api_url + '/create_invite', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': props.auth.getAccessToken()
      },
      body: JSON.stringify({
        "email": email,
        "client_id": auth.userProfile.app_metadata.apps.curb_pay[env].client_id,
        "company": auth.userProfile.user_metadata.company,
        "roles": "dashboard,transactions,deposits,invoices"
      }),
    })
      .then(response => response.json())
      .then(responseJson => {
        if (responseJson.Message === "Success") {
          setShowAlert(true);
          
          setFiltersVisible(!filtersVisible);
        }
      })
      .catch(error => {
        console.error(`An Unexpected Error Occurred while updating account, try again later`);
      });
  }

  return (
    <div>
      <div>
        <Button className='inviteButton' onClick={() => setFiltersVisible(!filtersVisible)}>Invite User</Button>
        <Alert show={showAlert} variant="success" onClose={() => {setShowAlert(false); setEmail("");}} dismissible>
          <p>
            Invite sent to {email}
          </p>
        </Alert>
      </div>
      {filtersVisible && (
          <Form.Group className="inviteDiv form-inline">
            {/* <Form.Label>Email:</Form.Label> */}
            <Form.Control
              className='inviteEmailInput'
              placeholder='example@domain.com'
              value={email}
              onChange={(event) => { setEmail(event.target.value) }}
              autoFocus
            />
            <Button className='inviteButton' onClick={sendInvite}>Send</Button>
          </Form.Group>
      )}
    </div>
  )
}

export default InviteUser