import { api_url } from '../config.json';

const GraphQL = (resource, args, access_token, response) => {
    return new Promise((resolve, reject) => {
        //construct the query
        var query = `{ ${resource}(`

        //append the arguments to the query
        for (var key in args) {
            if (args[key] && args[key] !== '' && args[key] !== `''`) {
                query += `${key}: ${args[key]}, `
            }
        }

        //trim trailing comma
        query = query.substring(0, query.length - 2);

        //append the fields wanted in the response
        query += `) {${response} }}`;

        fetch(api_url + '/query', {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${access_token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ query: query }),
        })
            .then(response => response.json())
            .then(responseJson => {
                if (responseJson.data) {
                    resolve(responseJson.data[resource]);
                } else {
                    reject(responseJson.error)
                }
            })
            .catch(error => {
                console.error(`An Unexpected Error Occurred while loading ${resource} data, please try again later.`)
                reject(error);
            });

    })

}

export default GraphQL