import { env } from '../config';
export default (auth) => {
    if (!auth.userProfile.app_metadata.apps.curb_pay[env]) {
        return false;
    }
    else if(!auth.userProfile.app_metadata.apps.curb_pay[env].client_id) {
        return false;
    }
    else if(!auth.userProfile.app_metadata.apps.curb_pay[env].roles) {
        return false;
    }
    else if(auth.userProfile.app_metadata.apps.curb_pay[env].roles && auth.userProfile.app_metadata.apps.curb_pay[env].roles.length === 0 ) {
        return false;
    }
    else{
        return true;
    }

}