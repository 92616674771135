import auth0 from 'auth0-js';
import { AUTH, host, host_path } from '../config';

export default class Auth {
  accessToken;
  idToken;
  expiresAt;
  tokenRenewalTimeout;
  scopes = '';
  requestedScopes = 'openid profile app_metadata email read:current_user';

  auth0 = new auth0.WebAuth({
    domain: AUTH.DOMAIN,
    audience: AUTH.AUDIENCE,
    clientID: AUTH.CLIENT_ID,
    redirectUri: host + host_path + '/callback',  
    responseType: 'token id_token',
    scope: this.requestedScopes,
  });

  constructor() {
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.getAccessToken = this.getAccessToken.bind(this);
    this.getIdToken = this.getIdToken.bind(this);
    this.silentAuth = this.silentAuth.bind(this);
    this.getProfile = this.getProfile.bind(this);

    this.scheduleRenewal();
  }

  handleAuthentication() {
    return new Promise((resolve, reject) => {
      this.auth0.parseHash((err, authResult) => {
        if (err) return reject(err);
        if (!authResult || !authResult.idToken) {
          return reject(err);
        }
        this.setSession(authResult);
        resolve();
      });
    })
  }

  getProfile() {
    return new Promise((resolve, reject) => {
      this.auth0Manage = new auth0.Management({
        domain: AUTH.DOMAIN,
        token: this.accessToken
      });

      this.auth0Manage.getUser(this.sub, (err, profile) => {
        if (profile) {
          this.userProfile = profile;
          resolve()
        }
        return reject(err);
      });
    })
  }

  getAccessToken() {
    return this.accessToken;
  }

  getIdToken() {
    return this.idToken;
  }

  setSession(authResult) {
    // Set isLoggedIn flag in localStorage
    localStorage.setItem('isLoggedIn', 'true');

    // Set the time that the access token will expire at
    let expiresAt = (authResult.expiresIn * 1000) + new Date().getTime();
    this.accessToken = authResult.accessToken;
    this.idToken = authResult.idToken;
    this.expiresAt = expiresAt;
    this.scopes = authResult.scope || this.requestedScopes || '';
    this.scheduleRenewal();
    this.sub = authResult.idTokenPayload.sub;
    this.idTokenPayload = authResult.idTokenPayload;
    this.getProfile((err, profile) => { this.userProfile = profile });

  }

  silentAuth() {
    return new Promise((resolve, reject) => {
      this.auth0.checkSession({}, (err, authResult) => {
        if (err) return reject(err);
        this.setSession(authResult);
        resolve();
      });
    });
  }


  login(username, password) {
    this.auth0.login(
      { realm: 'Username-Password-Authentication', username, password },
      (err, authResult) => {
        if (err) {
          console.log(err);
          alert(`Error: ${err.description}. Check the console for further details.`);
          return;
        }
      }
    );
  }

  changePassword(email) {
    this.auth0.changePassword({
      connection: 'Username-Password-Authentication', email
    },
      (err, resp) => {
        if (err) {
          console.log(err.message);
          return;
        } else {
          alert(resp);
        }
      }
    )
  }

  signup(email, password) {
    this.auth0.signup(
      { connection: 'Username-Password-Authentication', email, password },
      (err) => {
        if (err) {
          console.log(err);
          alert(`Error: ${err.description}. Check the console for further details.`);

          return;
        }

        this.auth0.login({ realm: 'Username-Password-Authentication', username: email, password },
          (err, authResult) => {
            if (err) {
              console.log(err);
              alert(`Error: ${err.description}. Check the console for further details.`);
              return;
            }
          }
        );
      }
    );
  }

  userHasScopes(scopes) {
    const grantedScopes = this.scopes.split(' ');
    return scopes.every(scope => grantedScopes.includes(scope));
  }

  logout() {
    // Remove tokens and expiry time
    this.accessToken = null;
    this.idToken = null;
    this.userProfile = null;
    this.expiresAt = 0;

    // Remove isLoggedIn flag from localStorage
    localStorage.removeItem('isLoggedIn');
    this.auth0.logout({
      returnTo: host + host_path,
      clientID: AUTH.CLIENT_ID,
    });
    clearTimeout(this.tokenRenewalTimeout);

  }

  isAuthenticated() {
    // Check whether the current time is past the
    // access token's expiry time
    let expiresAt = this.expiresAt;
    return new Date().getTime() < expiresAt;
  }

  scheduleRenewal() {
    let expiresAt = this.expiresAt;
    const timeout = expiresAt - Date.now();
    if (timeout > 0) {
      this.tokenRenewalTimeout = setTimeout(() => {
        this.silentAuth();
      }, timeout);
    }
  }

  getExpiryDate() {
    return JSON.stringify(new Date(this.expiresAt));
  }
}
