import React, { useState, useEffect } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import history from './history';

import Loading from "./components/Loading";
import Navigation from './components/Navigation';
import Footer from './components/Footer';

import Dashboard from './views/Dashboard';
import Signup from './views/Signup';
import Transactions from './views/Transactions';
import Deposits from './views/Deposits';
import Admin from './views/Admin';
import Profile from './views/Profile';
import Invoices from './views/Invoices';
import Login from './views/Login';
import Verify from './views/Verify';
import Callback from './Callback';

import Auth from './resources/Auth';
import validSession from './resources/validSession';

import './styles.css';

const auth = new Auth();

const App = (props) => {
  const [loading, setLoading] = useState(true);
  const [filtersVisible, setFiltersVisible] = useState(false);

  const handleClick = (e) => {
    if (e.target.className === "background") {
      setFiltersVisible(false);
    }
  }
  useEffect(() => {
    async function fetchData() {
      try {
        await auth.silentAuth();
        await auth.getProfile();
        setLoading(false);
      } catch (err) {
        console.log(err.error);
        setLoading(false);
      }
    }
    fetchData();
  }, []);

  if (loading) {
    return <Loading />
  }

  if ((!localStorage.getItem('isLoggedIn') && window.location.pathname !== '/callback') || (!loading && !auth.isAuthenticated())) {
    return (
      <Router history={history} >
        <Switch>
          <Route path="/signup" render={() => <Signup auth={auth} history={history} />} />
          <Route path="/verify" render={() => <Verify auth={auth} history={history} />} />
          <Route path="/callback" render={(props) => <Callback auth={auth} history={history} {...props} />} />
          <Route path="/" render={() => <Login auth={auth} />} />
        </Switch>
      </Router>
    )
  }

  if(!validSession(auth)){
    alert("Invalid Credentials");
    auth.logout();
  }
  
  return (
    <Router history={history} >
      <div className="background" onClick={handleClick}>
        <div className="content">
          <Navigation auth={auth} />
          <Switch>
            <Route path="/profile" render={() => <Profile auth={auth} />} />
            <Route path="/dashboard" render={() => <Dashboard auth={auth} />} />
            <Route path="/transactions" render={() => <Transactions auth={auth} filtersVisible={filtersVisible} setFiltersVisible={setFiltersVisible} />} />
            <Route path="/invoices" render={() => <Invoices auth={auth} filtersVisible={filtersVisible} setFiltersVisible={setFiltersVisible} />} />
            <Route path="/deposits" render={() => <Deposits auth={auth} filtersVisible={filtersVisible} setFiltersVisible={setFiltersVisible} />} />
            <Route path="/admin" render={() => <Admin auth={auth} filtersVisible={filtersVisible} setFiltersVisible={setFiltersVisible} />} />
            <Route path="/callback" render={(props) => <Callback auth={auth} history={history} {...props} />} />
            <Route path="/" render={() => <Dashboard auth={auth} />} />
          </Switch>
          <Footer />
        </div>
      </div>
    </Router>
  )

}

export default App;
