// src/Profile/Dashboard.js

import React from 'react';

import DashboardGraph from '../components/DashBoardGraph';

import {currency} from '../config';

const Dashboard = (props) => {

  return (
    <div className="vertical-cont">
      <DashboardGraph
        title={`Sales, ${currency}`}
        resource="sales"
        keys={["sales"]}
        auth={props.auth}
      />

      <DashboardGraph
        title="Volume"
        resource="volume"
        keys={["volume"]}
        auth={props.auth}
      />
    </div>
  );
}

export default Dashboard;