import React, { useState, useEffect } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import filterFactory from 'react-bootstrap-table2-filter';
import moment from 'moment';

import GraphQL from '../resources/GraphQL';

import spinner from "../assets/loading.svg";

import { env } from '../config.json';

const EmbeddedTable = (props) => {

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const [additionalArgs] = useState(props.args !== undefined ? props.args: {}  );

  useEffect(() => {
    setLoading(true);

    var args = {
      "client_id": props.auth.userProfile.app_metadata.apps.curb_pay[env].client_id,
      ...additionalArgs
    }


    GraphQL(props.resource, args, props.auth.getAccessToken(), props.response)
      .then(res => {
        setData(t => t.concat(res));
        setLoading(false);
      })
      .catch(err => {
        console.error(err);
        setLoading(false);
      });

  }, [props.auth, props.resource, props.response, additionalArgs]);

  return (
    <div className="table-component vertical-cont">
      <ToolkitProvider
        keyField={props.keyField}
        data={data}
        expandRow={props.expandRow}
        columns={props.columns}
        search
        exportCSV={{
          fileName: `${props.resource}-${moment().format("YYYY-MM-DD-H-MM-SS")}.csv`
        }}
      >
        {
          toolKitProps => (
            <div className="table-component">
              <div>
                <div className="embedded-table-cont">
                  <BootstrapTable {...toolKitProps.baseProps}
                    className="table"
                    bordered={false}
                    headerClasses="header"
                    filter={filterFactory()}
                    striped={true}
                    expandRow={props.expandRow}
                    {...props}
                  />
                </div>

                {loading && (<div className='table-spinner'><img src={spinner} alt="Loading" /></div>)}
              </div>
            </div>
          )
        }
      </ToolkitProvider>
    </div>

  )
}

export default EmbeddedTable;
