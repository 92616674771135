// src/Profile/Profile.js

import React, { useState, useEffect, useCallback } from 'react';
import { Form, Button } from 'react-bootstrap';

import Loading from "./Loading";

import { api_url } from '../config';

const CreateUser = (props) => {

  const urlParams = new URLSearchParams(window.location.search);
  const urlParamsCallBack = useCallback(urlParams, []);
  const [email, setEmail] = useState(urlParams.get("email"));
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [firstName, setFirstName] = useState(urlParams.get("first_name"));
  const [lastName, setLastName] = useState(urlParams.get("last_name"));
  const [phone, setPhone] = useState(urlParams.get("phone"));
  const [company, setCompany] = useState(urlParams.get("company"));
  const [loading, setLoading] = useState(true);
  const [reg_token, setRegToken] = useState();

  useEffect(() => {
    fetch(api_url + '/reg_token', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        "client_id": parseInt(urlParamsCallBack.get("client_id")),
        "link_token": urlParamsCallBack.get("token")
      }),
    })
      .then(response => response.json())
      .then(responseJson => {
        if (responseJson.Message === "Error: Unauthorized Access") {
          alert("Unauthorized Access: An invitation to curbpay is required to register");
          props.history.push('/');
        }
        setRegToken(responseJson.reg_token);
        setLoading(false);
      });

  }, [urlParamsCallBack, props.history]);

  if (!urlParamsCallBack.has("client_id") || !urlParamsCallBack.has("token")) {
    alert("Unauthorized Access: An invitation to curbpay is required to register");
    props.history.push('/');
  }

  function containsRequiredChars(str){
    return str.match(/(?=.{8,})((?=.*\d)(?=.*[a-z])(?=.*[A-Z])|(?=.*\d)(?=.*[a-zA-Z])(?=.*[\W_])|(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_])).*/) !== null;
  }

  const validatePassword = (password, confirmPassword) => {
    var status = true;
    if (password.length < 8){
      alert("Password must be at least 8 characters in length.");
      return false;
    }
    if(!containsRequiredChars(password)){
      alert(`Password requirements not met. Password must contain atleast 3 of the 4 types of characters: Lower case letters (a-z), Upper case letters (A-Z), Numbers (0-9), or Special Characters(e.g. !@#$%^&*)`);
      return false;
    }
    if (password !== confirmPassword) {
      alert("Passwords must match");
      return false;
    }
    return status;
  }

  const submit = (e) => {
    e.preventDefault();
    if (validatePassword(password, confirmPassword)) {
      fetch(api_url + '/CreateAuth0User', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          "email": email,
          "password": password,
          "firstname": firstName,
          "lastname": lastName,
          "phone": phone,
          "company": company,
          "client_id": urlParams.get("client_id"),
          "reg_token": reg_token
        }),
      })
        .then(response => response.json())
        .then(responseJson => {
          if (responseJson.ExceptionMessage && responseJson.ExceptionMessage === "The user already exists.") {
            alert("Your account already exists, please contact support for further help");
          } else {
            props.history.push('/verify');
          }
        })
        .catch(error => {
          console.error(`An Unexpected Error Occurred while creating account, try again later`);
        });
    }
  }

  if (loading) {
    return <Loading />
  }

  return (
    <Form className="signUpForm">
      <Form.Group>
        <h3 className="Create-Account">CREATE ACCOUNT</h3>

        <Form.Label>First Name</Form.Label>
        <Form.Control
          value={firstName}
          onChange={(event) => { setFirstName(event.target.value) }}
        />

        <Form.Label>Last Name</Form.Label>
        <Form.Control
          value={lastName}
          onChange={(event) => { setLastName(event.target.value) }}
        />

        <Form.Label>Email</Form.Label>
        <Form.Control
          value={email}
          onChange={(event) => { setEmail(event.target.value) }}
          disabled = { true }
          autoFocus
        />

        <Form.Label>Password</Form.Label>
        <Form.Control
          value={password}
          type="password"
          onChange={(event) => { setPassword(event.target.value) }}
        />


        <Form.Label>Confirm Password</Form.Label>
        <Form.Control
          value={confirmPassword}
          type="password"
          onChange={(event) => { setConfirmPassword(event.target.value) }}
        />


        <Form.Label>Phone Number</Form.Label>
        <Form.Control
          value={phone}
          onChange={(event) => { setPhone(event.target.value) }}
        />

        <Form.Label>Company</Form.Label>
        <Form.Control
          value={company}
          onChange={(event) => { setCompany(event.target.value) }}
        />

        <Button className="LoginButton align-center" type="submit" onClick={submit}>
          Submit
        </Button>

      </Form.Group>
    </Form>
  );
}

export default CreateUser;