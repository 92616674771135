import React, { useEffect } from 'react';
import Loading from './components/Loading';


const Callback = (props) => {

  const handleAuthentication = async () => {
    await props.auth.handleAuthentication();
    props.history.replace('/dashboard');
  }

  useEffect(() => {
    handleAuthentication();
  })

  return (<Loading />);
}

export default Callback;