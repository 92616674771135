import React from 'react';
import { Navbar, Nav, NavDropdown, Container } from 'react-bootstrap';
import { NavLink } from "react-router-dom";

import logo from '../assets/curb-logo/curb-logo.png';

import { env } from '../config';

const Navigation = (props) => {

  const { isAuthenticated, userProfile, logout } = props.auth;

  const getPages = () => {
    var pages = userProfile.app_metadata.apps.curb_pay[env].roles;
    return pages;
  }

  const genNavLinks = () => {
    var navPages = [];
    const pages = getPages();
    for (var page in pages) {
      navPages.push(
        <NavLink key={pages[page]} className="NavLink" to={`/${pages[page].replace(/ /g,'')}`}>{pages[page].toUpperCase()}</NavLink>
      );
    }
    return navPages;
  }

  return (
    <Container className="NavContainer">
      <Navbar variant="light" expand="lg" className="NavBar noselect" >
        {/* <Hamburger /> */}
        <Navbar.Brand>
          <img
            src={logo}
            className="d-inline-block curbNav"
            alt="CurbPay"
          />
          <span className="d-inline-block payNav">pay</span>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="NavBarCollapse" expand="lg">
          <Nav className="mr-auto items">
            {isAuthenticated() && (genNavLinks())}
          </Nav>
          <Nav className="float-right">
            {
              <NavDropdown
                variant=''
                title={userProfile.user_metadata.name.toUpperCase()}
                id="dropdown-menu-align-right"
                className="DropDown"
              >
                <NavDropdown.Item to="/profile" as={NavLink}>Profile</NavDropdown.Item>
                <NavDropdown.Item onClick={() => logout()}>Log Out</NavDropdown.Item>
              </NavDropdown>
            }
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </Container>
  )

}

export default Navigation;